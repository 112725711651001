<template>
    <div class="transparenciaOne">
        <headNav activeNav="transparencia"></headNav>
        <img src="../assets/image/5_banner.png" class="banner" />
        <div class="wrapper">
            <div class="privacidad_wrap">
                <div class="privacidad_tab_cont">
                    <div class="privacidad_tab_view">
                        <div class="privacidad_tab_item" :class="tabActive == 1 ? 'privacidad_tab_iactive' : ''" @click.stop="ontabChange(1)">
                            <span>Transparencia</span>
                        </div>
                        <div class="privacidad_tab_item privacidad_tab_icenter" :class="tabActive == 2 ? 'privacidad_tab_iactive' : ''" @click.stop="ontabChange(2)">
                            <span>Derechos y obligaciones de cobranza</span>
                        </div>
                        <div class="privacidad_tab_item" :class="tabActive == 3 ? 'privacidad_tab_iactive' : ''" @click.stop="ontabChange(3)">
                            <span>Aviso importante</span>
                        </div>
                    </div>
                </div>

                <div v-if="tabActive == 1">
                    <div class="privacidad_imgs_view">
                        <div class="privacidad_one_imgone">
                            <img src="../assets/image/privacidad_01.png" alt="" />
                        </div>
                        <div class="privacidad_one_imgtwo">
                            <img src="../assets/image/privacidad_02.png" alt="" />
                        </div>
                        <div class="privacidad_one_imgthr">
                            <img src="../assets/image/privacidad_03.png" alt="" />
                        </div>
                    </div>
                </div>
                <div v-if="tabActive == 2">
                    <div class="privacidad_imgs_view">
                        <div class="privacidad_two_imgone">
                            <img src="../assets/image/privacidad_04.png" alt="" />
                        </div>
                        <div class="privacidad_two_imgtwo">
                            <img src="../assets/image/privacidad_05.png" alt="" />
                        </div>
                        <div class="privacidad_two_imgthr">
                            <img src="../assets/image/privacidad_06.png" alt="" />
                        </div>
                    </div>
                </div>
                <div v-if="tabActive == 3">
                    <div class="privacidad_imgs_view">
                        <div class="privacidad_thr_imgone">
                            <img src="../assets/image/privacidad_07.png" alt="" />
                        </div>
                    </div>
                </div>


            </div>

        </div>
        <footPage></footPage>
    </div>
</template>
<script>
    import headNav from "@/components/headNav.vue";
    import footPage from "@/components/foot.vue";
    //import "@/components/scss/transparenciaOne.scss";

    export default {
        name: "equipo",
        components: { headNav, footPage },
        data() {
            return {
                tabActive: 1,
            };
        },
        methods: {
            ontabChange(index){
                this.tabActive = index;
            }
        },
    };
</script>
<style scoped lang="scss">
    .privacidad_wrap{
        width: 100%;
        height: auto;
    }
    .privacidad_tab_cont{
        width: 100%;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 56px 62px 60px 62px;
    }
    .privacidad_tab_view{
        width: 100%;
        height: 80px;
        background: #F5F5F5;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 8px;
        display: flex;
        justify-content: space-between;
    }
    .privacidad_tab_item{
        width: 350px;
        height: 64px;
        background: #F5F5F5;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .privacidad_tab_icenter{
        box-sizing: border-box;
        padding: 0 60px;
    }
    .privacidad_tab_item span{
        font-size: 20px;
        font-weight: 500;
        color: #B5B5B5;
        text-align: center;
        line-height: 22px;
    }
    .privacidad_tab_iactive{
        background: #FFFFFF;
    }
    .privacidad_tab_iactive span{
        color: #333333;
    }
    .privacidad_imgs_view{
        width: 100%;
        height: auto;
    }
    .privacidad_one_imgone{
        width: 100%;
        height: 1012px;
    }
    .privacidad_one_imgone img{
        display: block;
        width: 100%;
        height: 1012px;
    }
    .privacidad_one_imgtwo{
        width: 100%;
        height: 400px;
    }
    .privacidad_one_imgtwo img{
        display: block;
        width: 100%;
        height: 400px;
    }
    .privacidad_one_imgthr{
        width: 100%;
        height: 1800px;
    }
    .privacidad_one_imgthr img{
        display: block;
        width: 100%;
        height: 1800px;
    }
    .privacidad_two_imgone{
        width: 100%;
        height: 492px;
    }
    .privacidad_two_imgone img{
        display: block;
        width: 100%;
        height: 492px;
    }
    .privacidad_two_imgtwo{
        width: 100%;
        height: 1050px;
    }
    .privacidad_two_imgtwo img{
        display: block;
        width: 100%;
        height: 1050px;
    }
    .privacidad_two_imgthr{
        width: 100%;
        height: 922px;
    }
    .privacidad_two_imgthr img{
        display: block;
        width: 100%;
        height: 922px;
    }
    .privacidad_thr_imgone{
        width: 100%;
        height: 418px;
    }
    .privacidad_thr_imgone img{
        display: block;
        width: 100%;
        height: 418px;
    }
</style>
