<template>
    <div class="transparenciaOne">
        <headNav activeNav="transparencia"></headNav>
        <img src="../assets/image/5_banner.png" class="banner" />
        <div class="wrapper">
            <div class="equipo_wrap">
                <div class="equipo_artic_cont">
                    <div class="equipo_artic_title">
                        <img src="../assets/image/equipo_titlebg.png" alt="" />
                        <div class="equipo_artic_title_view">
                            <span>Evitar fraude</span>
                        </div>
                    </div>

                    <div class="equipo_artic_top">
                        <span>¿Qué es un fraude y cómo evitarlo?</span>
                    </div>
                    <div class="equipo_artic_down">
                        <p>
                            Cuando usan plataforma de préstamo hay que poner mucha atención en no caerse en trampas. El fraude aparece con mucha frecuencia al momento de realizar pagos. Existen delincuentes que ofrece referencias de pago falsas u ajenas a usuarios para liquidar su préstamo. Superapoyo cuenta con varias vías de pago para facilitar los usuarios, solo las referencias que aparecen en la aplicación son correctas. Es muy importante que no pagen a las referencias ajenas.
                        </p>
                    </div>
                </div>

                <div class="equipo_artic_cont" style="margin-top: 40px;">
                    <div class="equipo_artic_title">
                        <img src="../assets/image/equipo_titlebg.png" alt="" />
                        <div class="equipo_artic_title_view">
                            <span>Manejar crédito</span>
                        </div>
                    </div>

                    <div class="equipo_artic_top">
                        <span>¿Cómo manejo mi crédito en Superapoyo?</span>
                    </div>
                    <div class="equipo_artic_down">
                        <p>
                            Superapoyo ofrece un rango de monto de préstamo muy ancho entre $9000 y $20000. El plazo de préstamo va desde 91 días a 180 días. Superapoyo garantiza validación de solicitud dentro de 3 minutos, sin embargo, si usuario sube imagenes de identificación falsas u borrosas o ingresar informaciones incorrectas el tiempo de espera de validación se prolongará por 5 a 10 minutos más. Una diferencia significante entre Superapoyo y otras plataformas de préstamos es que el historial crediticio de cada usuario puede optimizar y actualizar, y más aún aumentar el monto de préstamo disponible. En total hay dos maneras para realizar la optimización:
                        </p>
                        <p>
                            - Manera 1: Pagar por adelantado
                        </p>
                        <p>
                            Pago adelantado refiere a usuarios que pagan sus pedidos antes de sus fechas de pago por 1-3 días. Pago adelantado tiene muchos beneficios, uno de ello es pagar menos interés, y el otro el que puede mejorar historial crediticio y aumentar monto de préstamo disponible.
                        </p>
                        <p>
                            - Manera 2: Pagar a tiempo
                        </p>
                        <p>
                            Pago a tiempo también puede mejorar historial crediticio y aumentar monto de préstamo disponible.
                        </p>
                    </div>

                </div>


            </div>

        </div>
        <footPage></footPage>
    </div>
</template>
<script>
    import headNav from "@/components/headNav.vue";
    import footPage from "@/components/foot.vue";
    //import "@/components/scss/transparenciaOne.scss";

    export default {
        name: "equipo",
        components: { headNav, footPage },
        data() {
            return {

            };
        },
        methods: {

        },
    };
</script>

<style scoped lang="scss">
    .equipo_wrap{
        width: 100%;
        height: auto;
        background: #FBFBFB;
    }
    .equipo_artic_cont{
        width: 100%;
        height: auto;
        background: #ffffff;
        box-sizing: border-box;
        padding: 36px 60px 50px 60px;
        border-radius: 10px;
    }
    .equipo_artic_title{
        width: 100%;
        height: 92px;
        position: relative;
    }
    .equipo_artic_title img{
        display: block;
        width: 100%;
        height: 92px;
    }
    .equipo_artic_title_view{
        width: 100%;
        height: 92px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .equipo_artic_title_view span{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
    }
    .equipo_artic_top{
        width: 100%;
        height: 114px;
        box-sizing: border-box;
        padding-top: 50px;
    }
    .equipo_artic_top span{
        font-size: 30px;
        font-weight: bold;
        color: #666666;
    }
    .equipo_artic_down{
        width: 100%;
        height: auto;
    }
    .equipo_artic_down p{
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
    }
    .equipo_imgone{
        width: 100%;
        height: 181px;
        margin-top: 40px;
    }
    .equipo_imgone img{
        display: block;
        width: 100%;
        height: 181px;
    }
    .equipo_imgs_view{
        width: 100%;
        height: 240px;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
    .equipo_imgs_item{
        width: 340px;
        height: 240px;
    }
    .equipo_imgs_item img{
        display: block;
        width: 340px;
        height: 240px;
    }
</style>
