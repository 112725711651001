<template>
    <div class="transparenciaOne">
        <headNav activeNav="transparencia"></headNav>
        <img src="../assets/image/5_banner.png" class="banner" />
        <div class="wrapper">
            <div class="equipo_wrap">
                <div class="equipo_artic_cont">
                    <div class="equipo_artic_title">
                        <img src="../assets/image/equipo_titlebg.png" alt="" />
                        <div class="equipo_artic_title_view">
                            <span>Beneficios de VIP</span>
                        </div>
                    </div>

                    <div class="equipo_artic_top">
                        <span>¿Conoces los niveles de VIP Superapoyo y sus beneficios?</span>
                    </div>
                    <div class="equipo_artic_down">
                        <p>
                            Nivel de VIP se sube siguiendo las cantidades de valores ganados en el transcurso del uso de SUPERAPOYO. Usuarios pueden subir nivel cuando tenga valores suficientes. Valores sirven para determinar el nivel de VIP de usuario. Usuarios pueden ganar valores tras descargar SUPERAPOYO, registrar cuenta, solicitar préstamos y pagar pedidos. El nivel de VIP se sube siguiendo el aumento de valores. Cuando más valores tengan un usuario, más alto su nivel de VIP será y más beneficios disfrutará.
                        </p>
                        <p>
                            Mercados de Puntos:
                        </p>
                        <p>
                            Usuarios pueden usar los puntos acumulados a canejar prendas y cupones en Mercado de Puntos de Superapoyo. Las prendas se puede canjear cada mes, el envio ocupa 7 a 15 dias normalmente. Los cupones canjeados en Mercado de Puntos solamente sirve para solicitar préstamo en SUPERAPOYO.
                        </p>
                        <p>
                            Reglas de Bajar y Subir Nivel:
                        </p>
                        <p>
                            Cuando usuario tenga suficientes valores (cantidad requerida en regala), puede subir o bajar nivel de VIP.
                        </p>
                        <p>
                            Explicación de Valores:
                        </p>
                        <p>
                            En total hay 5 niveles de VIP según los valores que tenga un usuario.
                        </p>
                        <p>
                            Valores y Niveles de VIP Correspondidos:
                        </p>
                        <p>
                            VIP 0  0
                        </p>
                        <p>
                            VIP 1  1~599
                        </p>
                        <p>
                            VIP 2  600-1199
                        </p>
                        <p>
                            VIP 3  1200-2999
                        </p>
                        <p>
                            VIP 4  3000+
                        </p>
                        <p>
                            Vigencia de Niveles:
                        </p>
                        <p>
                            Nivel de VIP está válido siempre, pero puede ser congelado según actividad de usuario. Regla: nivel de VIP actual se congela cuando usuario tenga más de 180 día sin solicitar préstamo en SUPERAPOYO. Pero estado congelado se terminará una vez usuario tenga depósito exitoso.
                        </p>
                        <p>
                            Beneficios de VIP:
                        </p>
                        <p>
                            Diferentes niveles de VIP disfrutan de diferentes beneficios, los beneficios de VIP detallados se muestran en la siguiente tabla:
                        </p>
                    </div>

                    <div class="producto_imgone">
                        <img src="../assets/image/producto_01.png" alt="" />
                    </div>
                </div>

                <div class="equipo_artic_cont" style="margin-top: 40px;">
                    <div class="equipo_artic_title">
                        <img src="../assets/image/equipo_titlebg.png" alt="" />
                        <div class="equipo_artic_title_view">
                            <span>Cupones y Descuentos</span>
                        </div>
                    </div>

                    <div class="equipo_artic_top">
                        <span>¿Conoces los cupones y promociones de Superapoyo?</span>
                    </div>
                    <div class="equipo_artic_down">
                        <p>
                            Cupones:
                        </p>
                        <p>
                            Superapoyo ofrece cupones para aumentar monto de préstamo disponible, prolongar plazos de préstamos SIN interés, descuento de pago, descuento de interés:
                        </p>
                        <p>
                            - Cupón de monto adicional: sirve para aumentar monto de préstamo disponible, se puede usar al solicitar el préstamo.
                        </p>
                        <p>
                            - Cupón de pago: equivalente a dinero en efectivo al momento de pagar el préstamo, cupón de pago reduce el monto de pago total.
                        </p>
                        <p>
                            - Descuento de interés: reduce la tasa de interés del préstamo.
                        </p>
                        <p>
                            - Extensión de plazo: prolonga plazo de préstamos por unos días. Hay dos tipos de cupón de plazo, uno con interés y otro SIN interés.
                        </p>
                        <p>
                            Promociones y Descuentos:
                        </p>
                        <p>
                            ¡Superapoyo organiza muchas promociones mensuales, usuarios tiene oportunidad de ganar regalos de cupones, saldo telefónico y premios físicos! Además, cuenta con sistema de VIP para usuarios. Cada préstamo solicitado puede ganar puntos, y usuarios puede usar puntos para canjear premios en Mercado de Puntos.
                        </p>
                    </div>

                    <div class="producto_imgtwo">
                        <img src="../assets/image/producto_02.png" alt="" />
                    </div>
                </div>
            </div>

        </div>
        <footPage></footPage>
    </div>
</template>
<script>
    import headNav from "@/components/headNav.vue";
    import footPage from "@/components/foot.vue";
    //import "@/components/scss/transparenciaOne.scss";

    export default {
        name: "equipo",
        components: { headNav, footPage },
        data() {
            return {

            };
        },
        methods: {

        },
    };
</script>

<style scoped lang="scss">
    .equipo_wrap{
        width: 100%;
        height: auto;
        background: #FBFBFB;
    }
    .equipo_artic_cont{
        width: 100%;
        height: auto;
        background: #ffffff;
        box-sizing: border-box;
        padding: 36px 60px 50px 60px;
        border-radius: 10px;
    }
    .equipo_artic_title{
        width: 100%;
        height: 92px;
        position: relative;
    }
    .equipo_artic_title img{
        display: block;
        width: 100%;
        height: 92px;
    }
    .equipo_artic_title_view{
        width: 100%;
        height: 92px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .equipo_artic_title_view span{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
    }
    .equipo_artic_top{
        width: 100%;
        height: 114px;
        box-sizing: border-box;
        padding-top: 50px;
    }
    .equipo_artic_top span{
        font-size: 30px;
        font-weight: bold;
        color: #666666;
    }
    .equipo_artic_down{
        width: 100%;
        height: auto;
    }
    .equipo_artic_down p{
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
    }
    .equipo_imgs_item img{
        display: block;
        width: 340px;
        height: 240px;
    }
    .producto_imgone{
        width: 100%;
        height: 734px;
        margin-top: 40px;
    }
    .producto_imgone img{
        display: block;
        width: 100%;
        height: 734px;
    }
    .producto_imgtwo{
        width: 100%;
        height: 326px;
    }
    .producto_imgtwo img{
        display: block;
        width: 100%;
        height: 326px;
    }
</style>
