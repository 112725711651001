<template>
    <div class="transparenciaOne">
        <headNav activeNav="transparencia"></headNav>
        <img src="../assets/image/5_banner.png" class="banner" />
        <div class="wrapper">
            <div class="equipo_wrap">
                <div class="equipo_artic_cont">
                    <div class="equipo_artic_title">
                        <img src="../assets/image/equipo_titlebg.png" alt="" />
                        <div class="equipo_artic_title_view">
                            <span>Historia de Superapoyo</span>
                        </div>
                    </div>

                    <div class="equipo_artic_top">
                        <span>¿Te gustaría conocer la historia de Superapoyo?</span>
                    </div>
                    <div class="equipo_artic_down">
                        <p>La historia de Superapoyo comenzó en marzo de 2021. Nos dimos cuenta de la amplia y activa demanda local de pequeñas cantidades de efectivo en México.</p>
                        <p>Especialmente entre los entre la población mas joven del país , que se dedican a emprender con nuevos negocios, están comprometidos con el desarrollo de sus pequeñas empresas.</p>
                        <p>Superapoyo fue atraído por esta atmósfera tan rica y esperaba poder utilizar su experiencia en el campo de las finanzas personales para ayudar a estos emprendedores en la medida de lo posible y contribuir a la realización de sus pequeños sueños individuales.</p>
                        <p>En poco más de dos años, hemos brindado apoyo financiero a casi un millón de personas que se dedican al comercio, con un total de cientos de millones de pesos en flujo de efectivo. Pequeñas cantidades, grandes sueños. Superapoyo , el asesor de gestión de efectivo a su lado.</p>
                    </div>
                </div>

                <div class="equipo_artic_cont" style="margin-top: 40px;">
                    <div class="equipo_artic_title">
                        <img src="../assets/image/equipo_titlebg.png" alt="" />
                        <div class="equipo_artic_title_view">
                            <span>Acción Social de Superapoyo</span>
                        </div>
                    </div>

                    <div class="equipo_imgone">
                        <img src="../assets/image/equipo_01.png" alt="" />
                    </div>

                    <div class="equipo_artic_top">
                        <span>Rescatar perros callejeros</span>
                    </div>
                    <div class="equipo_artic_down">
                        <p>Superapoyo está muy activo en las acciones sociales, recientemente hemos participado en rescatar perros callejeros con Milagros Caninos y ha realizado un donativo en especie y monetario. Milagros Caninos se trata del Primer Santuario para perros en America Latina dedicado exclusivamente a rescatar y darles una oportunidad de vida a perros en situaciones extremas.</p>
                    </div>

                    <div class="equipo_imgs_view">
                        <div class="equipo_imgs_item">
                            <img src="../assets/image/equipo_02.png" alt="" />
                        </div>
                        <div class="equipo_imgs_item">
                            <img src="../assets/image/equipo_03.png" alt="" />
                        </div>
                        <div class="equipo_imgs_item">
                            <img src="../assets/image/equipo_04.png" alt="" />
                        </div>
                    </div>
                </div>


            </div>

        </div>
        <footPage></footPage>
    </div>
</template>
<script>
    import headNav from "@/components/headNav.vue";
    import footPage from "@/components/foot.vue";
    //import "@/components/scss/transparenciaOne.scss";

    export default {
        name: "equipo",
        components: { headNav, footPage },
        data() {
            return {

            };
        },
        methods: {

        },
    };
</script>

<style scoped lang="scss">
    .equipo_wrap{
        width: 100%;
        height: auto;
        background: #FBFBFB;
    }
    .equipo_artic_cont{
        width: 100%;
        height: auto;
        background: #ffffff;
        box-sizing: border-box;
        padding: 36px 60px 50px 60px;
        border-radius: 10px;
    }
    .equipo_artic_title{
        width: 100%;
        height: 92px;
        position: relative;
    }
    .equipo_artic_title img{
        display: block;
        width: 100%;
        height: 92px;
    }
    .equipo_artic_title_view{
        width: 100%;
        height: 92px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .equipo_artic_title_view span{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
    }
    .equipo_artic_top{
        width: 100%;
        height: 114px;
        box-sizing: border-box;
        padding-top: 50px;
    }
    .equipo_artic_top span{
        font-size: 30px;
        font-weight: bold;
        color: #666666;
    }
    .equipo_artic_down{
        width: 100%;
        height: auto;
    }
    .equipo_artic_down p{
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
    }
    .equipo_imgone{
        width: 100%;
        height: 181px;
        margin-top: 40px;
    }
    .equipo_imgone img{
        display: block;
        width: 100%;
        height: 181px;
    }
    .equipo_imgs_view{
        width: 100%;
        height: 240px;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
    .equipo_imgs_item{
        width: 340px;
        height: 240px;
    }
    .equipo_imgs_item img{
        display: block;
        width: 340px;
        height: 240px;
    }
</style>
