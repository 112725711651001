<template>
  <div class="foot">
    <div class="footLeft">
      <img style="width: 181px" src="../assets/image/logo2.png" alt="" />
    </div>
    <div class="footLeft">
      <div class="footappdown" style="width: 425px; display: flex;justify-content: center; align-items: center;">
        <a
                href="https://bit.ly/4cvDIyo"
                class="playBtn"
                target="_blank"
        ><img src="@/assets/image/playBtnv2.png" alt=""
        /></a>

        <!--<a href="javascript:void(0)">
          <img src="@/assets/image/footer_ios.png" alt="" />
        </a>-->
      </div>

    </div>
    <div class="footRight">
      <div class="top">
        <img src="../assets/image/youxiang.png" /> Queja de cobranza:
      </div>
      <div>superapoyo.cobranza.mx@gmail.com</div>
    </div>
  </div>
</template>

<script>
import "./scss/foot.scss";

export default {
  name: "footPage",
  data() {
    return {};
  },
};
</script>
