import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import sobre from "../views/sobre.vue"
import aviso from "../views/Aviso.vue"
import ayuda from "../views/ayuda.vue"
import loan from "../views/loan.vue"
import transparenciaOne from "../views/transparenciaOne.vue"
import transparenciaTwo from "../views/transparenciaTwo.vue"
import transparenciaThree from "../views/transparenciaThree.vue"
import Equipo from "../views/Equipo.vue"
import Producto from "../views/Producto.vue"
import Finanza from "../views/Finanza.vue"
import Privacidad from "../views/Privacidad.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/about',
    name: 'about',
    component: sobre
  },
  {
    path: '/policy',
    name: 'policy',
    component: aviso
  },
  {
    path: '/notice',
    name: 'notice',
    component: aviso
  },
  {
    path: '/help',
    name: 'help',
    component: ayuda
  },
  {
    path: '/loan',
    name: 'loan',
    component: loan
  },
  {
    path: '/transparenciaOne',
    name: 'transparencia',
    component: transparenciaOne
  },
  {
    path: '/transparenciaTwo',
    name: 'transparencia',
    component: transparenciaTwo
  },
  {
    path: '/transparenciaThree',
    name: 'transparencia',
    component: transparenciaThree
  },
  {
    path: '/Equipo',
    name: 'transparencia',
    component: Equipo
  },
  {
    path: '/Producto',
    name: 'transparencia',
    component: Producto
  },
  {
    path: '/Finanza',
    name: 'transparencia',
    component: Finanza
  },
  {
    path: '/Privacidad',
    name: 'transparencia',
    component: Privacidad
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
